.footer {
  padding: var(--padding-large) 0 var(--padding-medium);
  text-align: center;
}

.footer__link {
  font-size: var(--fs-small);
  font-weight: var(--fw-semi-bold);
  color: var(--clr-fg);
}

@media (max-width: 600px) {
  .footer {
    padding: var(--padding-medium);
    margin-top: var(--margin-extra-extra-large);
  }
}
