.nav__list {
  display: flex;
  margin-right: var(--margin-regular);
}

.nav__list-item {
  margin-left: var(--margin-regular);
  font-size: var(--fs-medium);
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
  background-color: transparent;
}

@media (max-width: 600px) {
  .nav__list {
    background-color: black;
    opacity: var(--opacity-menu);

    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin-top: var(--margin-extra-small);
    margin-left: 0;
  }

  .nav__list-item a {
    color: var(--clr-nav-item);
  }

  .app .nav__hamburger {
    background-color: transparent;
    margin-left: var(--margin-small);
    display: flex;
    z-index: 2;
  }
}
